<template>
  <div class="join_step1">
    <div class="text">
      <h1 class="text_name">{{ Contract.name }}</h1>
        <div class="text_content">{{ Contract.content }}</div>
          <div class="text_btn">
            <template>
              <el-radio v-model="agree" label="1">了解且同意商家入驻协议</el-radio>
            </template>
            <el-button :disabled="!agree" type="success" @click="Sign">同意协议，进入下一步</el-button>
          </div>
        </div>
    </div>
</template>

<script>
  import { signContractApi } from '@/api/api'
  export default {
    props: ['Contract'],
    data() {
      return {
        agree: 0
      }
    },
    created() {
      this.getContract()
    },
    methods: {
      // 确认签署协议
      Sign() {
        let data = {
          contract_id: this.Contract.id
        }
        signContractApi(data).then(res => {
          this.$router.push('/store/join/step_2')
        })
      },
      // 获取协议
      getContract() {
        this.$emit('getActiveStep', 0)
      }
    }
  }
</script>

<style lang="less" scoped>
  .join_step1 {

    .text {
      .text_name {
        height: 50px;
        line-height: 50px;
      }

      .text_content {
        line-height: 30px;
        font-size: 12px;
      }

      .text_btn {
        margin: 40px auto;
      }
    }
  }
</style>